<template>
  <div class="bg-white">
    <div class="pt-9 pb-9">
      <div class="container">
        <div class="row">
          <div class="offset-xl-2 col-xl-8 offset-lg-1 col-lg-10 col-md-12 col-12">
            <div class="text-center mb-5">
              <h1 class="display-2 fw-bold">Tin tức</h1>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="pb-12">
      <div class="container">
        <div class="row">
          <div class="col-xl-4 col-lg-4 col-md-6 col-12" v-for="page in pages.data" :key="'page' + page.code"
          >
            <div class="card mb-4 shadow-lg ">
              <router-link class="card-img-top" :to="{name: 'PageDetail', params: {code: page.code, slug: page.slug}}">
                <img :src="page.thumbnail ? page.thumbnail.link : '/assets/images/avatar/avatar.jpg'" :alt="page.name" class="card-img-top rounded-top-md max-height-200 object-fit-cover">
              </router-link>
              <div class="card-body">
                <h3>
                  <router-link class="text-inherit" :to="{name: 'PageDetail', params: {code: page.code, slug: page.slug}}">
                    {{ page.name }}
                  </router-link>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
  name: "PageDetail",
  data() {
    return {
      pages: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      }
    }
  },
  methods: {
    loadPages() {
      let query = `query {
        mySchool {
          pages(first: 10) {
            data {
              name
              slug
              code
              thumbnail {
                link
              }
              created_at
            }
            paginatorInfo {
              currentPage
              lastPage
              total
            }
          }
        }
      }`;

      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data && data.data.mySchool && data.data.mySchool.pages) {
              this.pages = data.data.mySchool.pages;
            }
          })
          .catch(({response}) => {
            console.log(response);
          });
    }
  },
  mounted() {
    this.loadPages();
  }
}
</script>